<template lang="pug">
  v-card
    v-card-title.pt-5.pl-7.text-center Добавить привязку
    v-card-text.pt-0
      v-row
        v-col.pb-0(cols="12")
          OrganizationPicker(
            v-model="selectedOrgId"
            :items="organizations"
            :error-messages="er_selectedOrgId"
          )
      v-row
        v-col.pt-3(cols="12")
          v-select(
            label="Группа"
            v-model="selectedGroupId"
            :items="groupList"
            item-value="id"
            item-text="name"
            :error-messages="er_selectedGroupId"
          )  
      v-row(align-end)
        v-col.d-flex.justify-center
          v-btn.mr-3.text--secondary(
            text
            @click="$emit('close')"
          ) Отмена
          v-btn(
            depressed
            color="primary"
            @click="onSubmit"
          ) Добавить  
</template>

<script>
import { mapGetters } from 'vuex';

import mixins from '@/utils/mixins';
import Validate from '@/validations/bindingAdd';

import OrganizationPicker from '@/components/controls/OrganizationPicker';

export default mixins(Validate).extend({
  name: 'BindingAdd',
  components: {
    OrganizationPicker,
  },

  data() {
    return {
      selectedOrgId: null,
      selectedHostId: null,
      selectedGroupId: null,
      radioGroup: 0,
    };
  },

  computed: {
    ...mapGetters('NOTIFICATIONS', ['groupList']),
    ...mapGetters(['organizations']),
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: `Ошибка валидации`,
          text: '',
        });
        return;
      }

      const query = {
        orgId: this.selectedOrgId,
        groupId: this.selectedGroupId,
      };

      try {
        await this.$store.dispatch('BINDINGS/addBinding', query);
        this.$notify({
          group: 'note',
          type: 'info',
          title: `Новая привязка добавлена`,
          text: '',
        });
        this.$v.$reset();
        this.$store.dispatch('NOTIFICATIONS/queryOrgList', true);
        this.$emit('update');
        this.$emit('close');
      } catch (e) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: `Произошла ошибка добавления привязки. Попробуйте позже.`,
          text: '',
        });
      }
    },
  },
});
</script>
