<template lang="pug">
  v-autocomplete(
    v-model="internalValue"
    :outlined="outlined"
    :dense="dense"
    hide-details="auto"
    label="Точка осмотра"
    :items="innerItems"
    :disabled="selectedOrganizationsCount !== 1 || disabled"
    :hint="selectedOrganizationsCount !== 1 ? 'Для выбора точек, выберите одну организацию' : ''"
    persistent-hint
    multiple
    item-value="id"
    :item-text="orgHostBinded ? 'hostname' : 'name'"
    :error-messages="selectedCount === 0 ? 'Выберите хотя бы одну точку осмотра' : null"
  )
    template(v-slot:prepend-item)
      v-list-item(@click="toggleAll")
        v-list-item-action
          v-simple-checkbox(
            @input="toggleAll"
            :value="!!selectedCount"
            :indeterminate="!selectedCount ? false : !isAllItemsSelected"
          )
        v-list-item-content
          v-list-item-title Выбрать все точки осмотра
    template(v-slot:selection="{ item, index }")
      div(
        v-if="index === 0"
        class="v-select__selection v-select__selection--comma"
      )
        span(v-if="isAllItemsSelected") Все точки осмотра
        span(
          v-else
          :style="{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}"
          ) {{ orgHostBinded ? item.hostname : item.name }}
      span(
        v-if="index === 1 && !isAllItemsSelected"
        class="grey--text caption"
      ) + еще {{internalValue.length - 1}}

</template>

<script>
// FIXME: rework this component like OrganizationPicker
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    outlined: Boolean,
    dense: Boolean,
    organizations: {
      type: [Array, Number],
      default: () => [],
    },
    allSelectedToNull: {
      type: Boolean,
      default: true,
    },
    // показывает есть ли связи между организациями и хостнеймами
    orgHostBinded: Boolean,
  },

  data() {
    return {
      internalValue: [{ id: null }],
    };
  },
  computed: {
    selectedOrganizationsCount() {
      if (!this.orgHostBinded || typeof this.organizations === 'number') {
        return 1;
      } else if (Array.isArray(this.organizations)) {
        return this.organizations.length;
      }
      // Oleg, REVIEW: is this a correct default value to return?
      return 0;
    },
    innerItems() {
      if (this.selectedOrganizationsCount !== 1) {
        return [{ id: null }];
      }

      if (this.organizations.length || typeof this.organizations === 'number') {
        const orgId = Array.isArray(this.organizations)
          ? this.organizations[0]
          : this.organizations;

        return this.items.filter(item => {
          return item.organization.id === orgId;
        });
      }

      return this.items;
    },
    selectedCount() {
      return this.internalValue.length;
    },
    isAllItemsSelected() {
      return this.internalValue.length === this.innerItems.length;
    },
  },
  watch: {
    value(value, oldValue) {
      if (value !== this.internalValue) {
        this.internalValue = value
          ? value.slice()
          : this.innerItems.map(item => item.id);
      }
    },
    internalValue(value) {
      if (this.allSelectedToNull) {
        this.$emit(
          'input',
          value.length === this.innerItems.length ? null : value,
        );
      } else {
        this.$emit('input', value);
      }
    },
    organizations(value) {
      this.internalValue = this.innerItems.map(item => item.id);
    },
  },
  mounted() {
    if (this.innerItems.length !== 0) {
      this.internalValue = this.innerItems.map(item => item.id);
    }
    const unwatch = this.$watch('innerItems', () => {
      this.internalValue = this.value
        ? this.value.slice()
        : this.innerItems.map(item => item.id);
      unwatch();
    });
  },
  methods: {
    toggleAll() {
      this.$nextTick(() => {
        if (this.isAllItemsSelected) {
          this.internalValue = [];
        } else {
          this.internalValue = this.innerItems.map(item => item.id);
        }
      });
    },
  },
};
</script>
