<template lang="pug">
ListPageLayout(title="Список привязок")
  template(#filters)
    v-col.d-flex.align-start(cols="12" sm="8" md="6" lg="4")
      v-btn-toggle.mr-3(
        v-model="orgType"
        mandatory
        dense
      )
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(value="org" v-on="on" color="light-grey")
              v-icon(small) fa-hotel
          span Организация      
  
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(value="host" v-on="on" color="light-grey")
              v-icon(small) fa-desktop
          span Хостнейм
      OrganizationPicker(
        v-if="orgType==='org'"
        v-model="selectedOrgs"
        :items="orgList"
        :disabled="$root.isDisabled('bindings')"
        multiple
        can-select-all
        dense
        outlined
        hide-details="auto"
      )
      SelectHostname(
        v-if="orgType==='host'"
        v-model="selectedHosts"
        :items="hostList"
        :disabled="$root.isDisabled('notificatonsHosts')"
        :orgHostBinded="false"
        outlined
        dense
      )

    v-col(cols="12" sm="4" md="3" lg="3")
      MultipleSelect(
        label="Группа"
        placeholder="Выберите группу"
        v-model="selectedGroups"
        :items="groupList"
        item-value="id"
        item-text="name"
        textAllSelected="Все группы"
        :hideAfter="1"
        :disabled="$root.isDisabled('bindings')"
        outlined
        dense
      )
    v-col(cols="12" sm="12" md="3" lg="5")
      v-btn.float-right(
        text
        @click="resetPageAndBindings()"
        :disabled="$root.isDisabled('workers')"
      ) Сбросить фильтры  

  template(#actions)
    //- v-btn.mr-6(
    //-   v-if="$can('BINDINGS_ADD')"
    //-   @click="toggleDialog('add')"
    //-   color="primary"
    //-   depressed
    //- ) Добавить
    v-btn.mr-6(
      v-if="$can('READ_NOTIFY_RECIPIENT')"
      to="list"
      color="primary"
      depressed
    ) Список получателей 

  template(#list)
    v-data-table(
      :headers="headers"
      :items="items"
      item-key="id"
      :loading="$root.isLoading('bindings')"
      hide-default-footer
      :items-per-page.sync="limit"
      :sort-by.sync="order"
      :sort-desc.sync="orderType"
      no-data-text="Нет привязок по данным фильтрам"
      loading-text=""
    )
      template(#progress)
        Spinner

      template(#header)
        div.hidden-xs-only
          PerPagePicker(
            v-model="limit"
            data-table
          )  

      template(#top)
        v-dialog(
          v-model="showEditDialog"
          max-width="600"
          v-if="showEditDialog"
        )
          EditBinding(
            v-if="showEditDialog"
            :editItem="currentItem"
            :groupList="groupList"
            :loading="$root.isLoading('bindings')"
            @update="fetchList"
            @close="toggleDialog('edit')"
          )
        v-dialog(
          v-if="showAddDialog"
          v-model="showAddDialog"
          max-width="600"
        )
          AddBinding(
            @update="fetchList"
            @close="toggleDialog('add')"
          )

      template(#item.id="{ item }")
            span {{item.id}}

      template(#item.group="{ item }")
        span {{item.group ? item.group.name : ""}}

      template(#item.organizations="{ item }")
        v-icon(color="brown") {{item.host ? "fas fa-desktop" : "fas fa-hotel"}}
        span.pl-1 {{item.host ? item.host.name : item.orgName}}

      template(#item.actions="{ item }")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-btn(
              v-on="on"
              icon
              v-if="$can('BINDINGS_EDIT')"
              @click="toggleDialog('edit', item)"
            )
              v-icon(small) fa-pencil-alt
          span Редактировать

  template(#footer)
    v-pagination(
      v-show="isShowPagination"
      v-model="currentPage"
      :length="totalPages"
      total-visible="10"
    )        
</template>

<script>
import ListPageLayout from '@/components/layouts/ListPageLayout';
import OrganizationPicker from '@/components/controls/OrganizationPicker.vue';
import SelectHostname from '@/components/controls/SelectHostname.vue';
import MultipleSelect from '@/components/controls/MultipleSelect';
import Spinner from '@/components/Spinner.vue';
import PerPagePicker from '@/components/controls/PerPagePicker';
import AddBinding from './AddBinding.vue';

import { mapActions, mapGetters } from 'vuex';
import { mapGettersMutations } from '@/utils/vuex';

export default {
  name: 'NotificationsList',

  components: {
    EditBinding: () =>
      import('@/views/admin-notifications/bindings/EditBinding.vue'),
    AddBinding,
    ListPageLayout,
    Spinner,
    PerPagePicker,
    OrganizationPicker,
    SelectHostname,
    MultipleSelect,
  },

  data() {
    return {
      headers: [
        {
          name: 'id',
          text: 'ID',
          value: 'id',
          align: 'center',
          sortable: true,
        },
        {
          name: 'groups',
          text: 'Группа',
          value: 'group',
          align: 'center',
          sortable: true,
        },
        {
          name: 'organizations',
          value: 'organizations',
          text: 'Организация/Хостнейм',
          align: 'left',
          sortable: true,
        },
        {
          name: 'actions',
          value: 'actions',
          text: 'Действия',
          align: 'center',
          sortable: false,
        },
      ],
      showAddDialog: false,
      showEditDialog: false,
      currentItem: null,
    };
  },

  computed: {
    ...mapGetters('NOTIFICATIONS', [
      'groupList',
      'groupIds',
      'orgList',
      'hostList',
    ]),

    ...mapGetters('BINDINGS', [
      'query',
      'items',
      'totalPages',
      'isShowPagination',
    ]),
    ...mapGettersMutations('BINDINGS', {
      currentPage: 'currentPage',
      limit: 'limit',
      order: 'orderBy',
      orderType: 'orderType',
    }),

    ...mapGettersMutations('BINDINGS', {
      selectedOrgs: 'selectedOrgs',
      selectedHosts: 'selectedHosts',
      selectedGroups: 'selectedGroups',
      orgType: 'orgType',
    }),
  },

  mounted() {
    if (!this.orgList.length) {
      this.$store.dispatch('NOTIFICATIONS/queryOrgList');
    }
    if (!this.hostList.length) {
      this.$store.dispatch('NOTIFICATIONS/queryHostList');
    }
    if (!this.groupList.length) {
      this.$store.dispatch('NOTIFICATIONS/getGroups');
    }
  },

  methods: {
    ...mapActions('BINDINGS', ['fetchList']),

    toggleDialog(action, item = null) {
      this.currentItem = item;
      action === 'edit'
        ? (this.showEditDialog = !this.showEditDialog)
        : (this.showAddDialog = !this.showAddDialog);
    },

    resetPageAndBindings() {
      this.$store.dispatch('BINDINGS/resetFilters');
    },
  },
};
</script>
